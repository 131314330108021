// 代币 对象信息

import { useAppStore } from '@store/appStore';
import { bpMul, bpDiv, bpGte, bpGt, bpFormat } from '@/utils/bpMath';
import { toRaw } from 'vue';
import i18n from '@/utils/i18n';
import { ElMessage } from 'element-plus';

const appStore = useAppStore();
const $t = i18n.global.t;
export default class {
  /**
   * 构建
   * @param {Object} addressObj 地址对象：包括地址和abi
   */
  constructor(addressObj) {
    this.coinObj = null; // 代币合约对象
    this.decimals = null; // 精度
    this.balance = null; // 余额
    this.balanceOrigin = null; // 余额原始值 hex

    this.craeteCoinToken(addressObj);
  }

  /**
   * 构建代币对象
   * @param {Object} addressObj：包括合约地址、abi
   * 例如 去旁边的 address.js 里拿 BVG_TOKEN_CONT 传入
   * @returns 代币的信息
   */
  craeteCoinToken(addressObj) {
    const { ethers, signer } = appStore.ethObj;
    const coinObj = new ethers.Contract(addressObj.address, addressObj.abi, toRaw(signer));
    this.coinObj = coinObj;
    return this.coinObj;
  }

  /**
   * 获取该代币精度
   * @returns {Number} 精度
   */
  async getDecimals() {
    // console.log('getDecimals',this.coinObj)
    this.decimals = await this.coinObj.decimals();
    return this.decimals;
  }

  /**
   * 获取代币余额
   * @param {String} accountAddr 用户地址
   * @returns {Number} 余额
   */
  async getBalance(accountAddr = appStore.defaultAccount) {
    // 如果该对象已经设置了精度，就不要再去浪费请求了
    if (!this.decimals) {
      await this.getDecimals();
    }
    const tempBalan = await this.coinObj.balanceOf(accountAddr);
    // this.balance = bpDiv(tempBalan, 10 ** this.decimals);
    this.balance = bpFormat(tempBalan, -3, +this.decimals);
    this.balanceOrigin = tempBalan;
    return this.balance;
  }

  /**
   * 余额是否充足
   * @param {} val
   */
  isBalanceEnough(val) {
    // console.log('isBalanceEnough。。',bpGte(this.balance, val),this.balance,val);
    return bpGte(this.balance, val);
  }

  /**
   * 判断是否授权
   * @param {String} accountAddr 用户地址
   * @param {String} contractAddr 合约地址
   * @param {String} amount 对比授权额度
   * @returns {Boolean} true 已经授权，false 没有授权
   */
  async allow(accountAddr, contractAddr,amount = '0') {
    const addr = accountAddr || appStore.defaultAccount;
    const allowance = this.coinObj.allowance(addr, contractAddr);
    // const balance = this.coinObj.balanceOf(addr);
    
    const [hasAllowance] = await Promise.all([allowance]);
    console.log('判断是否授权 allowance',hasAllowance.toString(), amount.toString())

    return bpGte(hasAllowance, amount);
  }

  /**
   * 授权
   * @param {String} contractAddr 合约地址
   * @param needMsg 需要提示信息
   */
  async auth(contractAddr, needMsg) {
    return new Promise(async (resolve, reject) => {
      const { ethers } = appStore.ethObj;

      try {
        const approve = await this.coinObj.approve(contractAddr, ethers.constants.MaxUint256);
        await approve.wait?.().then(resp => {
          if (needMsg) {
            ElMessage({
              message: $t('msg.3'),
              type: 'success'
            });
          }
          // 不提示
          resolve();
        });
      } catch (error) {
        if (needMsg) {
          let info = error?.reason || error?.message || error?.data?.message;
          // 点击了拒绝信息
          if (info?.includes?.('User denied transaction')) {
            info = 'User denied transaction signature.';
          }
          // 点击了拒绝信息
          if (error?.includes?.('cancel')) {
            info = 'User denied transaction signature.';
          }
          // 避免信息太长看懵用户
          info = String(info).length > 100 ? $t('msg.4') : info;
          ElMessage({
            message: info,
            type: 'error'
          });
        }
        // 错误不提示，自行处理
        reject(error);
      }
    });
  }
}
